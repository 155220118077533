import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import moment from "moment";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./mock/mock.js";
import { Message } from "element-ui";
import { VueJsonp } from "vue-jsonp";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueJsonp);
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$message = Message;

router.beforeEach((to, from, next) => {
  //登录
  if (to.path === "/login") return next();

  // 首页
  // 微信阅读产品
  if (
    [
      "/commandstat",
      "/commandStat",
      "/m/index",
      "/pc/index",
      "/read",
      "/",
      "/cron1800",
      "/m/commanddemo",
      "/m/commanddemo1",
      '/alipay3301',
      '/cpa5501',
      '/ks',
      '/kuaishou',
      '/ka',
      "/m/tongchengtravel",
      "/m/tongcheng_welfare",
      "/m/tongcheng_jinka",
      '/tc6601',
      '/tc6602',
      '/m/tongcheng_jinka_foshan'
    ].includes(to.path)
  )
    return next();

  const user = JSON.parse(window.sessionStorage.getItem("user"));
  if (!user || !user.token) {
    return next("/login");
  }
  Vue.prototype.user = user;

  Vue.prototype.axios = axios.create({
    params: { token: user.token },
  });

  next();
});

//定时器
// setInterval(() => {
//   Vue.prototype.$axios.get(process.env.VUE_APP_BASE_API + "/api/leon");
// }, 10000);


Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");
